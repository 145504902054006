import { useState } from 'react';
import type { NextPage } from 'next';
import { GetServerSideProps } from 'next';

import { AuthLanguageDropdown, AuthLayout, Icons, LoginBanner } from '@app/components';
import LoginWithOtp from 'components/Login/LoginWithOtp';
import LoginWithPassword from 'components/Login/LoginWithPassword';
import useLocale from '@app/hooks/useLocale';

type LoginPropType = {
  hostname: string;
};

const Login: NextPage<LoginPropType> = ({ hostname }) => {
  const SCOPE_OPTIONS = {
    scope: 'pages.login',
  };
  const { t } = useLocale();
  const [phoneLogin, setPhoneLogin] = useState(true);

  function changeLoginType() {
    setPhoneLogin((current) => !current);
  }

  return (
    <AuthLayout>
      <div className="absolute right-12 top-12 bg-gray-350 rounded-3 px-4 py-1">
        <AuthLanguageDropdown />
      </div>
      <div className="h-full flex">
        <div className="overflow-hidden relative flex-[2] flex flex-col justify-between items-center bg-gradient-to-b py-12 px-8 from-[#3067F4] to-[#143FAA] rounded-r-25 rounded-br-25">
          <LoginBanner hostname={hostname} />
        </div>
        <div className="flex-[4] flex items-center justify-center flex-col">
          <div className="w-[80%] lg:w-[40%] xl:w-[35%]">
            {phoneLogin ? <LoginWithOtp /> : <LoginWithPassword />}
            <button
              onClick={changeLoginType}
              className="flex items-center justify-center w-full mt-10 gap-2 rounded-4 border border-black-800 py-1.5"
            >
              {phoneLogin ? (
                <>
                  <Icons.Email width={20} /> {t('labels.emailLogin', SCOPE_OPTIONS)}
                </>
              ) : (
                <>
                  <Icons.Phone width={20} /> {t('labels.phoneLogin', SCOPE_OPTIONS)}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const hostname = req.headers.host;

  return {
    props: {
      hostname,
    },
  };
};

export default Login;
